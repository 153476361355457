import { render, staticRenderFns } from "./TooltipButton.vue?vue&type=template&id=bb26386a"
import script from "./TooltipButton.vue?vue&type=script&lang=ts"
export * from "./TooltipButton.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports