var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"track-item d-flex flex-column align-start hover-show-parent px-1",style:(_vm.style)},[_c('v-row',{staticClass:"pt-2 justify-center item-row",attrs:{"no-gutters":"","align":"center"}},[(_vm.solo)?_c('div',{staticClass:"type-color-box",style:({
        backgroundColor: _vm.color,
      })}):_c('v-checkbox',{staticClass:"my-0 ml-1 pt-0",attrs:{"dense":"","hide-details":"","input-value":_vm.inputValue,"color":_vm.color},on:{"change":function($event){return _vm.trackFilters.updateCheckedId(_vm.track.trackId, $event)}}}),_c('v-tooltip',{attrs:{"open-delay":"200","bottom":"","max-width":"200","disabled":_vm.track.trackId.toString().length < 8},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"trackNumber pl-0 pr-2",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handler.trackSeek(_vm.track.trackId)}}},on),[_vm._v(" "+_vm._s(_vm.track.trackId)+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.track.trackId)+" ")])]),(_vm.track.set)?_c('v-chip',{attrs:{"outlined":"","x-small":"","color":_vm.typeStyling.annotationSetColor(_vm.track.set)}},[_vm._v(" "+_vm._s(_vm.track.set)+" ")]):_vm._e(),_c('v-spacer'),_c('TypePicker',_vm._b({attrs:{"value":_vm.trackType},on:{"input":function($event){return _vm.setTrackType($event)}}},'TypePicker',{
        lockTypes: _vm.lockTypes, readOnlyMode: _vm.readOnlyMode, allTypes: _vm.allTypes, selected: _vm.selected,
      },false))],1),_c('v-row',{staticClass:"my-1 justify-center item-row flex-nowrap",attrs:{"no-gutters":""}},[(!_vm.isTrack)?_c('v-spacer'):_vm._e(),(_vm.selected)?[_c('span',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"},{name:"mousetrap",rawName:"v-mousetrap",value:([
          { bind: 'k', handler: _vm.toggleKeyframe },
          { bind: 'i', handler: _vm.toggleInterpolation },
          { bind: 'ctrl+i', handler: _vm.toggleAllInterpolation },
          { bind: 'home', handler: () => _vm.$emit('seek', _vm.track.begin) },
          { bind: 'end', handler: () => _vm.$emit('seek', _vm.track.end) },
        ]),expression:"[\n          { bind: 'k', handler: toggleKeyframe },\n          { bind: 'i', handler: toggleInterpolation },\n          { bind: 'ctrl+i', handler: toggleAllInterpolation },\n          { bind: 'home', handler: () => $emit('seek', track.begin) },\n          { bind: 'end', handler: () => $emit('seek', track.end) },\n        ]"}]}),_c('tooltip-btn',{attrs:{"color":"error","icon":"mdi-delete","disabled":_vm.merging || _vm.readOnlyMode,"tooltip-text":`Delete ${_vm.isTrack ? 'Track' : 'Detection'}`},on:{"click":function($event){return _vm.handler.removeTrack([_vm.track.trackId])}}}),(!_vm.multiCam)?_c('span',[(_vm.isTrack)?_c('tooltip-btn',{attrs:{"disabled":!_vm.track.canSplit(_vm.frame) || _vm.merging || _vm.readOnlyMode,"icon":"mdi-call-split","tooltip-text":"Split Track"},on:{"click":function($event){return _vm.handler.trackSplit(_vm.track.trackId, _vm.frame)}}}):_vm._e(),(_vm.isTrack && !_vm.readOnlyMode)?_c('tooltip-btn',{attrs:{"icon":(_vm.feature.isKeyframe)
            ? 'mdi-star'
            : 'mdi-star-outline',"disabled":_vm.keyframeDisabled,"tooltip-text":"Toggle keyframe"},on:{"click":_vm.toggleKeyframe}}):_vm._e(),(_vm.isTrack && !_vm.readOnlyMode)?_c('tooltip-btn',{attrs:{"icon":(_vm.feature.shouldInterpolate)
            ? 'mdi-vector-selection'
            : 'mdi-selection-off',"tooltip-text":"Toggle interpolation, ctrl+click to toggle all interpolation"},on:{"click":function($event){return _vm.clickToggleInterpolation($event)}}}):_vm._e()],1):_c('span',[_c('tooltip-btn',{attrs:{"icon":"mdi-camera","tooltip-text":"Open MultiCamera Tools"},on:{"click":_vm.openMultiCamTools}})],1)]:_vm._e(),(_vm.isTrack)?_c('v-spacer'):_vm._e(),(_vm.isTrack)?[_c('tooltip-btn',{attrs:{"icon":"mdi-chevron-double-left","tooltip-text":"Seek to track beginning"},on:{"click":function($event){return _vm.$emit('seek', _vm.track.begin)}}}),_c('tooltip-btn',{attrs:{"icon":"mdi-chevron-left","tooltip-text":"Seek to previous keyframe"},on:{"click":_vm.gotoPrevious}}),_c('tooltip-btn',{attrs:{"icon":"mdi-chevron-right","tooltip-text":"Seek to next keyframe"},on:{"click":_vm.gotoNext}}),_c('tooltip-btn',{attrs:{"icon":"mdi-chevron-double-right","tooltip-text":"Seek to track end"},on:{"click":function($event){return _vm.$emit('seek', _vm.track.end)}}})]:_c('tooltip-btn',{attrs:{"icon":"mdi-map-marker","tooltip-text":"Seek to detection"},on:{"click":function($event){return _vm.$emit('seek', _vm.track.begin)}}}),(!_vm.merging)?_c('tooltip-btn',{attrs:{"icon":(_vm.editing) ? 'mdi-pencil-box' : 'mdi-pencil-box-outline',"tooltip-text":"Toggle edit mode","disabled":!_vm.inputValue || _vm.readOnlyMode},on:{"click":function($event){return _vm.handler.trackEdit(_vm.track.trackId)}}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }